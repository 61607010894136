import React from 'react';
import { ImageContainer, Image, Text, Layout } from './ColumnElements';
import donkey1 from './../images/Eselhof1.jpg';
import donkey2 from './../images/Eselhof2.jpg';

const Eselhof = () => (
  <Layout
    sectionId="eselhof"
    title="Eselhof"
    titleSpace
    slopeOffset="130px"
    slopeHeight="545px"
    slopeBorders
    left={
      <React.Fragment>
        <Text indentLeft align="right" large>
          <p>
            Einen Tag mal mit den Augen eines Esels sehen. Entschleunigen Sie
            mit Kind, Kegel und Esel und genießen Sie die Haard beim
            Eselwandern. Lernen Sie die Eigenheiten der lieben Grautiere
            persönlich kennen und wenn Sie schon einmal einen Blick auf Toni,
            Harry und Toffifee werfen möchten, dann besuchen Sie die drei auf
            Ihrer eigenen Website:
            <a
              rel="noopener noreferrer"
              href="http://eselhof-stimberg.de/"
              target="_blank"
            >
              {' '}
              eselhof-stimberg.de
            </a>
          </p>
          <p>
            Der Eselhof Stimberg wurde im Jahre 2017 mit dem Ziel gegründet, es
            gestressten Menschen zu ermöglichen einen Tag in der Natur zu
            erleben.
          </p>
        </Text>
      </React.Fragment>
    }
    right={
      <React.Fragment>
        <ImageContainer indentLeft>
          <Image src={donkey1} />
        </ImageContainer>
        <ImageContainer indentLeft>
          <Image src={donkey2} />
        </ImageContainer>
      </React.Fragment>
    }
  />
);

export default Eselhof;

import React from 'react';
import styled from 'styled-components';
import { cssVariables } from '../config';

const Wrapper = styled.h2`
  color: ${cssVariables.color.green1};
  font-size: 28px;
  line-height: 32px;
  padding: 0;
  text-align: center;
  font-weight: normal;
  position: relative;
  z-index: 2;
  font-family: pacifico, sans-serif;
  margin: ${({ bottom }) => (bottom ? '0 0 80px' : '0 0 50px')};

  @media (min-width: ${cssVariables.breakPoints.mobile}) {
    font-size: 48px;
    line-height: 85px;
  }
`;

const Heading = (props) => (
  <Wrapper bottom={props.bottom}>{props.title}</Wrapper>
);

export default Heading;

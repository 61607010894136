import React from 'react';
import styled, { css } from 'styled-components';
import { cssVariables } from '../config';

const Wrapper = styled.div`
  position: relative;
  margin-top: 250px;
  z-index: 3;
  display: none;

  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    display: block;
  }

  ${(props) =>
    props.small &&
    css`
      margin-top: 0;
    `};
`;

const ImageWrapper = styled.div`
  width: 390px;
  height: auto;
  position: absolute;
  top: -250px;
  left: 20px;

  ${(props) =>
    props.small &&
    css`
      width: 160px;
      left: auto;
      right: 225px;
      top: -215px;
    `};

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 1200px) {
    ${(props) =>
      !props.small &&
      css`
        width: 200px;
        top: -90px;
      `};
  }
`;

const SideLogo = (props) => (
  <Wrapper small={props.small}>
    <ImageWrapper small={props.small}>{props.children}</ImageWrapper>
  </Wrapper>
);

export default SideLogo;

import React from 'react';
import styled, { css } from 'styled-components';
import { cssVariables } from '../config';

const Rotation = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1;
  top: ${({ offset }) => (offset ? offset : '20px')};
`;

const Inner = styled.div`
  height: ${({ height }) => (height ? height : '750px')};
  transform: skewY(4.55deg);
  opacity: ${({ solid }) => (solid ? '1' : '0.3')};
  background-image: linear-gradient(-124deg, #aed6f1 0%, #e5f5ec 100%);

  ${(props) =>
    props.flip &&
    css`
      transform: skewY(-4.55deg);
      background-image: linear-gradient(-124deg, #e5f5ec 0%, #aed6f1 100%);
    `}

  ${(props) =>
    props.borders &&
    css`
      transform: none;
    `}
  
  ${(props) =>
    props.image &&
    css`
      background-image: url(${(props) => props.image});
      background-repeat: no-repeat;
      background-position: left;
      background-size: cover;
      opacity: 1;

      @media (min-width: ${cssVariables.breakPoints.tablet}) {
        background-position: 0 -120px;
      }
    `}
`;

const Border1 = styled.div`
  border-top: 1px solid rgba(125, 206, 160, 0.65);
  border-bottom: 1px solid rgba(125, 206, 160, 0.65);
  padding: 5px 0;
  transform: skewY(4.55deg);

  ${(props) =>
    props.flip &&
    css`
      transform: skewY(-4.55deg);
    `}
`;

const Border2 = styled.div`
  border-top: 1px solid rgba(125, 206, 160, 0.35);
  border-bottom: 1px solid rgba(125, 206, 160, 0.35);
  padding: 5px 0;
`;

function Rectangle(props) {
  if (props.borders) {
    return (
      <Rotation offset={props.offset} image={props.image}>
        <Border1 flip={props.flip}>
          <Border2 flip={props.flip}>
            <Inner
              height={props.height}
              image={props.image}
              borders={props.borders}
              flip={props.flip}
            />
          </Border2>
        </Border1>
      </Rotation>
    );
  } else {
    return (
      <Rotation offset={props.offset} image={props.image}>
        <Inner height={props.height} image={props.image} flip={props.flip} solid={props.solid} />
      </Rotation>
    );
  }
}

export default Rectangle;

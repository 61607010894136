import React from 'react';
import styled from 'styled-components';
import { cssVariables } from '../config';
import RichText from './RichText';
import LeafSvg from '../Svg/LeafSvg';

const Outer = styled.div`
  padding-top: 40px;
`;

const Wrapper = styled.div`
  position: relative;
  background-color: #e5f5ec;
  max-width: 480px;
  padding: 60px 20px 20px;
  margin: 0;
  border: 1px solid #196f3d;
  border-radius: 15px;
  text-align: center;

  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    padding: 70px 50px 50px;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: -40px;
  left: calc(50% - 40px);
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #196f3d;
  background-color: ${cssVariables.color.white1};
`;

const ColoredBox = (props) => (
  <Outer>
    <Wrapper>
      <IconContainer>
        <LeafSvg />
      </IconContainer>
      <RichText>{props.children}</RichText>
    </Wrapper>
  </Outer>
);

export default ColoredBox;

import React from 'react';
import styled from 'styled-components';
import ColoredBox from './ColoredBox';
import { cssVariables } from '../config';
import CampingBackground from '../images/background-camping.jpg';

const Wrapper = styled.div`
  background: url(${CampingBackground}) no-repeat bottom center;
  background-size: cover;
  position: relative;
  background-position: center 57%;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 20px 200px;
  max-width: 1180px;
  margin: 0 auto;

  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    justify-content: flex-end;
    padding-right: 12%;
  }
`;

export default () => (
  <Wrapper>
    <Content>
      <ColoredBox>
        <h3>Willkommen in der Natur</h3>
        <p>
          Ich heiße Sie herzlich Willkommen auf dem Campingplatz Stimberg, der
          in Datteln am Rande des Ruhrgebiets direkt an der Haard liegt. Die
          Haard ist ein 55 km² großes Waldgebiet und bietet mehr als 167
          Kilometer Wanderwege, ein etwa 200 Kilometer großes Reitwegenetz und
          gut ausgebaute Fahrradwege.
        </p>
        <p>
          Genießen Sie Natur, Ruhe und erholen sich vom Stress des Alltags. In
          familiärer Atmosphäre bieten ich Ihnen Dauerstellplätze für Wohnwagen
          und Mobilheime von 100 bis 300 qm. Jede einzelne Parzelle ist eine
          kleine grüne Oase.
        </p>
      </ColoredBox>
    </Content>
  </Wrapper>
);

import styled from 'styled-components';
import { cssVariables } from '../config';

const RichText = styled.div`
  font-size: 18px;

  h3 {
    color: ${cssVariables.color.green1};
    font-size: 24px;
    line-height: 1.2em;
    margin-top: 0;
    margin-bottom: 1em;
    font-weight: normal;
    font-family: pacifico, sans-serif;
  }

  p {
    color: #4a4a4a;
    font-size: 1em;
    line-height: 1.45em;
    margin-top: 0;
    margin-bottom: 1em;
  }

  table {
    margin-bottom: 1em;
    width: 100%;
  }

  table,
  th,
  td {
    border: none;
  }

  th {
    text-align: right;
  }

  td {
    width: 50%;

    @media (min-width: ${cssVariables.breakPoints.tablet}) {
      &:first-child {
        width: 155px;
      }
    }
  }

  td,
  th {
    color: #4a4a4a;
    font-size: 16px;
    line-height: 1.45em;
    margin-top: 0;
    margin-bottom: 1em;
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

export default RichText;

import React from 'react';
import Slider from 'react-slick';
import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';
import Image from './Image';
import styled from 'styled-components';
import Slider1 from './../images/Slider1.jpg';
import Slider2 from './../images/Slider2.jpg';
import Slider4 from './../images/Slider4.jpg';
import Slider5 from './../images/Slider5.jpg';
import Slider6 from './../images/Slider6.jpg';
import Slider7 from './../images/Slider7.jpg';
import Slider8 from './../images/Slider8.jpg';
import Slider9 from './../images/Slider9.jpg';
import Slider3 from './../images/Slider3.jpg';
import ArrowRight from './../images/arrow-right.svg';
import ArrowLeft from './../images/arrow-left.svg';
import { cssVariables } from '../config';

const Nextarrow = ({ currentSlide, slideCount, children, ...props }) => (
  <span {...props}>{children}</span>
);
const Prevarrow = ({ currentSlide, slideCount, children, ...props }) => (
  <span {...props}>{children}</span>
);

const Wrapper = styled.div`
  max-width: 1440px;
  position: relative;
  margin: 50px auto;
  padding: 0 20px;

  .slick-slide {
    opacity: 0.4;
    transition: all 300ms;
  }

  .slick-active {
    opacity: 1;
  }

  .slick-arrow {
    position: absolute;
    top: calc(50% - 25px);
    width: 50px;
    height: 70px;
    z-index: 1;
    opacity: 0.8;
    &:before {
      display: none;
    }
    &:hover {
      opacity: 0.8;
    }
  }

  .slick-next {
    right: 60px;
    left: auto;
    @media (max-width: ${cssVariables.breakPoints.mobile}) {
      bottom: -63px;
      top: auto;
      z-index: 3;
      right: 0;
    }
  }

  .slick-prev {
    left: 60px;
    right: auto;
    @media (max-width: ${cssVariables.breakPoints.mobile}) {
      bottom: -63px;
      left: 0;
      top: auto;
      z-index: 3;
    }
  }

  .slick-dots {
    box-sizing: border-box;
    position: static;
    z-index: 2;
    margin-top: 20px;
    button {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      &:before {
        display: none;
      }
    }
    li {
      margin: 0 10px 0 0;
      padding: 0;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background: #ffffff;
      border: 1px solid #196f3d;
    }
    .slick-active {
      background-color: #e5f5ec;
    }
  }
`;

const ImageContainer = styled.div`
  object-fit: cover;
  margin: 0 auto;
  max-width: 800px;
  overflow: hidden;
  @media (max-width: ${cssVariables.breakPoints.tablet}) {
    margin: 0 20px;
  }
`;

class SimpleSlider extends React.Component {
  constructor(props) {
    super(props);

    // This is a workaround to fix Slick's behavior on initial render. Without the delay the slides are not centered
    // and spaced correctly. See https://github.com/akiran/react-slick/issues/1248
    this.state = { delayed: false };
    setTimeout(() => this.setState({ delayed: true }), 100);
  }

  render() {
    // See comment in constructor().
    if (!this.state.delayed) {
      return null;
    }

    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      prevArrow: (
        <Prevarrow>
          <img src={ArrowLeft} alt="" />
        </Prevarrow>
      ),
      nextArrow: (
        <Nextarrow>
          <img src={ArrowRight} alt="" />
        </Nextarrow>
      ),
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            centerMode: false,
          },
        },
        {
          breakpoint: 1300,
          settings: {
            centerMode: true,
            centerPadding: '150px',
          },
        },
        {
          breakpoint: 1440,
          settings: {
            centerMode: true,
            centerPadding: '200px',
          },
        },
        {
          breakpoint: 10000,
          settings: {
            centerMode: true,
            centerPadding: '250px',
          },
        },
      ],
    };
    return (
      <Wrapper>
        <Slider {...settings}>
          <div>
            <ImageContainer>
              <Image src={Slider8} alt="" />
            </ImageContainer>
          </div>
          <div>
            <ImageContainer>
              <Image src={Slider1} alt="" />
            </ImageContainer>
          </div>
          <div>
            <ImageContainer>
              <Image src={Slider2} alt="" />
            </ImageContainer>
          </div>
          <div>
            <ImageContainer>
              <Image src={Slider3} alt="" />
            </ImageContainer>
          </div>
          <div>
            <ImageContainer>
              <Image src={Slider4} alt="" />
            </ImageContainer>
          </div>
          <div>
            <ImageContainer>
              <Image src={Slider5} alt="" />
            </ImageContainer>
          </div>
          <div>
            <ImageContainer>
              <Image src={Slider6} alt="" />
            </ImageContainer>
          </div>
          <div>
            <ImageContainer>
              <Image src={Slider7} alt="" />
            </ImageContainer>
          </div>
          <div>
            <ImageContainer>
              <Image src={Slider9} alt="" />
            </ImageContainer>
          </div>
        </Slider>
      </Wrapper>
    );
  }
}
export default SimpleSlider;

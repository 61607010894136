import React from 'react';
import styled from "styled-components";
import RichText from "./RichText";
import { Layout } from './ColumnElements';
import MobileHomes from '../images/image-20220523-192105.png';
import { cssVariables } from '../config';


const Wrapper = styled.div`
  position: relative;
  margin-top: -100px;
  padding-bottom: 100px;
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 200px 20px 0;
  max-width: 1180px;
  margin: 0 auto;
  position: relative;
  z-index: 100;
`;

const Visual = styled.div`
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.21);
  border: 1px solid #196f3d;
  margin-bottom: 120px;
  
  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    margin-top: -80px;
    margin-bottom: 0;
  }`;

const ColoredBox = styled.div`
  position: relative;
  background-color: #fff;
  max-width: 480px;
  padding: 40px 20px 20px;
  margin: 0;
  border: 1px solid #196f3d;
  border-radius: 15px;
  text-align: left;
  margin-bottom: 20px;

  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    padding: 50px;
  }`

const MobileHome = () => (
  <Layout
    sectionId="mobile-homes"
    slopeHeight="713px"
    left={
      <React.Fragment>
        <ColoredBox>
          <RichText>
            <h3>Erweiterungsfläche für Mobilheime.</h3>
            <p>Demnächst erweitern wir unsere Campinganlage um einen Teilbereich ausschließlich für Mobilheime ab Baujahr 2020. Die Parzellen werden eine ungefähre Größe von 250 qm haben.</p>
            <p>
              <strong>Fertigstellung voraussichtlich erstes Quartal 2023.</strong>
            </p>
            <p><small>Auch hier gilt: kein Erstwohnsitz möglich.</small></p>
          </RichText>
        </ColoredBox>
      </React.Fragment>
    }
    right={
      <React.Fragment>
        <Visual>
          <img src={ MobileHomes } alt=""/>
        </Visual>
      </React.Fragment>
    }
  />
);

export default MobileHome;

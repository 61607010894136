import React from 'react';
import { Text, Layout } from './ColumnElements';
import Rectangle from './Rectangle';
import Banner1 from './../images/Hirsch.jpg';

const Wildfarm = () => (
  <Layout
    sectionId="wildfarm"
    title="Wildfarm"
    left={
      <React.Fragment>
        <Text indentLeft align="right" large>
          <p>
            Besuchern und Bewohnern unseres Campingplatzes können im Wechsel der
            Jahreszeiten das Damwild beobachten und mit viel Glück im Frühjahr
            ja vielleicht sogar die Geburt eines Hirschkälbchens live
            miterleben.
          </p>
        </Text>
      </React.Fragment>
    }
    right={
      <React.Fragment>
        <Text indentLeft large>
          <p>
            Unser Hirschgatter wird im Sinne landwirtschaftlicher Wildhaltung
            geführt. Die Produktion des Wildfleisches unterliegt schärfster
            behördlicher Kontrolle.
          </p>
        </Text>
      </React.Fragment>
    }
  >
    <div
      style={{
        display: 'block',
        height: '620px',
        position: 'relative',
        overflow: 'visible',
      }}
    >
      <Rectangle height="620px" image={Banner1} />
    </div>
  </Layout>
);

export default Wildfarm;

import React from 'react';
import styled, { css } from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { cssVariables } from '../config';
import { Link } from 'gatsby';

const Wrapper = styled.ul`
  position: relative;
  height: 60px;
  display: flex;
  justify-content: center;
  background-color: ${cssVariables.color.green1};
  margin: 0;
  padding: 0;
  list-style: none;

  @media (max-width: ${cssVariables.breakPoints.tablet}) {
    height: auto;
    flex-direction: column;
  }
`;

const NavigationItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  ${(props) =>
    props.hideOnMobile &&
    css`
      @media (max-width: ${cssVariables.breakPoints.tablet}) {
        display: none;
      }
    `};

  a {
    color: ${cssVariables.color.white1};
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: all ease-in-out 320ms;
    font-size: 18px;

    &:hover {
      border-color: ${cssVariables.color.white1};
    }
  }

  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    & + & {
      margin-left: 33px;
    }
  }
`;

const navLinks = [
  {
    label: 'Camping & Preise',
    offset: 80,
    id: '#camping',
  },
  {
    label: 'Lageplan',
    offset: 80,
    id: '#lageplan',
  },
  {
    label: 'Reiterhof',
    offset: 80,
    id: '#reiterhof',
  },
  {
    label: 'Eselhof',
    offset: 80,
    id: '#eselhof',
  },
  {
    label: 'Wildfarm',
    offset: 80,
    id: '#wildfarm',
  },
  {
    label: 'Kontakt',
    offset: 150,
    id: '#footer',
  },
];

const Navi = (props) => {
  const getTagFor = (aLink) =>
    props.isSamePage ? (
      <AnchorLink offset={aLink.offset} href={aLink.id}>
        {aLink.label}
      </AnchorLink>
    ) : (
      <Link offset={aLink.offset} to={`/${aLink.id}`}>
        {aLink.label}
      </Link>
    );

  return (
    <Wrapper>
      {navLinks.map((navLink) => (
        <NavigationItem key={navLink.id}>{getTagFor(navLink)}</NavigationItem>
      ))}
    </Wrapper>
  );
};

export default Navi;

import React from 'react';
import { ImageContainer, Image, Text, Layout } from './ColumnElements';
import ridingCenter1 from './../images/Reiterhof1.jpg';
import ridingCenter2 from './../images/Reiterhof2.jpg';

const Reiterhof = () => (
  <Layout
    sectionId="reiterhof"
    title="Reiterhof"
    slopeOffset="100px"
    slopeHeight="550px"
    slopeBorders
    slopeFlip
    headingSpace
    left={
      <React.Fragment>
        <Text indentLeft align="right" large>
          <p>
            Auf unserem Reiterhof bieten wir die Pferdehaltung für
            Selbstversorger an.
          </p>
          <p>
            Wir bieten dazu Pferdeboxen, Stroh und Heu. Als zusätzliches Angebot
            finden Sie bei uns einen Reitplatz, einen Paddock und eine
            Reithalle.
          </p>
          <p>Ferner stehen gut gepflegte Weideflächen zur Verfügung.</p>
          <p>
            Von unserem Reiterhof kann man das über 200 Kilometer Gesamtlänge
            ausgebaute Reitwegnetz der Haard auf direktem Wege erreichen.
          </p>
          <p>
            Für Ihre Wäsche haben wir einen kleinen Waschsalon der mit einer
            Waschmaschine und einem Trockner ausgestattet ist.
          </p>
        </Text>
      </React.Fragment>
    }
    right={
      <React.Fragment>
        <ImageContainer indentLeft>
          <Image src={ridingCenter1} />
        </ImageContainer>
        <ImageContainer indentLeft>
          <Image src={ridingCenter2} />
        </ImageContainer>
      </React.Fragment>
    }
  />
);

export default Reiterhof;

import React from 'react';
import { ImageContainer, Image, Text, Layout } from './ColumnElements';
import Camping1 from './../images/Camping1.jpg';
import Camping2 from './../images/Camping2.jpg';
import Camping3 from './../images/Camping3.jpg';

const Camping = () => (
  <Layout
    sectionId="camping"
    slopeOffset="100px"
    slopeHeight="713px"
    title="Camping"
    left={
      <React.Fragment>
        <Text indentLeft indentRight align="right">
          <h3>Ausstattung & Preise</h3>
          <p>
            All unsere Stellplätze verfügen über Trinkwasser- und
            Elektroanschlüsse und sind mit Kanalisation ausgestattet. Die
            Parzellen sind eingegrünt und die Campingplatzanlage ist beleuchtet
            sowie mit einer Zaunanlage umgeben. Sollte es für Sie von Interesse
            sein, so wäre auch Winterwasser möglich –<br />
            sprechen Sie mich darauf an.
            <br />
            Unsere Sanitäranlagen sind ausgestattet mit Duschen, Waschräumen und
            zahlreichen WC-Kabinen.
          </p>
          <p>
            Für Ihre Wäsche haben wir einen kleinen Waschsalon der mit einer
            Waschmaschine und einem Trockner ausgestattet ist.
          </p>
        </Text>
        <Text indentLeft indentRight align="right">
          <h3>Preisliste</h3>
          <table>
            <thead>
              <tr>
                <th colSpan="2">
                  <strong>Stand Juli 2022</strong>
                </th>
              </tr>
              <tr>
                <th colSpan="2">
                  <strong>Preise jährlich</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pachtzins</td>
                <td>
                  7,50 € pro m<sup>2</sup>
                </td>
              </tr>
              <tr>
                <td>Frisch- und Abwasser</td>
                <td valign={"top"}>240,00 €</td>
              </tr>
              <tr>
                <td>Müll</td>
                <td>55,00 €</td>
              </tr>
              <tr>
                <td>Besucherkarte</td>
                <td>26,00 €</td>
              </tr>
              <tr>
                <td>Hund</td>
                <td>45,00 €</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Strom</td>
                <td>0,55 € je kW/h</td>
              </tr>
            </tbody>
          </table>
        </Text>
        <ImageContainer>
          <Image src={Camping3} />
        </ImageContainer>
      </React.Fragment>
    }
    right={
      <React.Fragment>
        <ImageContainer>
          <Image src={Camping1} />
        </ImageContainer>
        <ImageContainer>
          <Image src={Camping2} />
        </ImageContainer>
        <Text indentLeft indentRight>
          <h3>Spielplätze</h3>
          <p>
            Ein großer Spielplatz nebst einem Bolzplatz ist ein schöner
            Treffpunkt für die Jugendlichen unseres Campingplatzes. Für die
            Jüngsten ist aber auch gesorgt, die sicher auf einem separaten und
            sicher eingezäunten Spielplatz ihre Zeit verbringen können.
          </p>
        </Text>
      </React.Fragment>
    }
  />
);

export default Camping;

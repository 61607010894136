import React from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Intro from '../components/Intro';
import SideLogo from '../components/SideLogo';
import PageHead from '../components/PageHead';
import Navi from '../components/Navi';
import Camping from '../components/Camping';
import MobileHome from "../components/mobile-home";
import Reiterhof from '../components/Reiterhof';
import Eselhof from '../components/Eselhof';
import Wildfarm from '../components/Wildfarm';
import GroundPlan from '../components/Map';
import Footer from '../components/Footer';
import MetaFooter from '../components/MetaFooter';
import ImageSlider from '../components/Slider';
import GoogleMap from '../components/GoogleMap';

// images
import camper from './../images/camper.svg';
import camper2 from './../images/camper2.svg';

export default () => (
  <Layout>
    <Header />
    <PageHead />
    <Navi isSamePage />
    <Intro />
    <MobileHome />
    <SideLogo>
      <img src={camper} alt="" />
    </SideLogo>
    <Camping />
    <ImageSlider />
    <GroundPlan />
    <Reiterhof />
    <Eselhof />
    <Wildfarm />
    <Footer />
    <SideLogo small>
      <img src={camper2} alt="" />
    </SideLogo>
    <GoogleMap />
    <MetaFooter />
  </Layout>
);

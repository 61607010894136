import React from 'react';
import styled, { css } from 'styled-components';
import Heading from './Heading';
import Rectangle from './Rectangle';
import BaseImage from './Image';
import RichText from './RichText';
import { cssVariables } from '../config';

const Wrapper = styled.div`
  position: relative;
`;

const Row = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    display: flex;
  }
`;

const Column = styled.div`
  padding: 0 20px;

  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    padding: 0 10px;
    width: 50%;
  }
`;

export const Text = styled(RichText)`
  margin-bottom: 20px;
  text-align: center;

  ${({ large }) =>
    large &&
    css`
      font-size: 24px;
      line-height: 36px;
    `}

  table {
    text-align: left;
    width: 75%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    tr td :last-child {
      text-align: right;
    }
  }

  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    margin-left: ${({ indentLeft }) => (indentLeft ? '16.66%' : '0')};
    margin-right: ${({ indentRight }) => (indentRight ? '16.66%' : '0')};
    margin-bottom: 50px;
    text-align: ${({ align }) => (align ? align : 'left')};

    table {
      margin-left: 25%;
    }
  }
`;

export const Image = styled(BaseImage)`
  margin-left: auto;
  margin-right: auto;
`;

export const ImageContainer = styled.div`
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    margin-left: ${({ indentLeft }) => (indentLeft ? '16.66%' : '0')};
    margin-right: ${({ indentRight }) => (indentRight ? '16.66%' : '0')};
  }
`;

export const Layout = (props) => (
  <Wrapper id={props.sectionId}>
    <Heading bottom={props.titleSpace} title={props.title} />
    <Row>
      <Column>{props.left}</Column>
      <Column>{props.right}</Column>
    </Row>
    {props.slopeHeight && (
      <Rectangle
        flip={props.slopeFlip}
        borders={props.slopeBorders}
        offset={props.slopeOffset}
        height={props.slopeHeight}
      />
    )}
    {props.children}
  </Wrapper>
);

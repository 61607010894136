import React from 'react';

export default () => {
  return (
    <svg width="48px" height="46px" viewBox="0 0 48 46" version="1.1">
      <g
        id="Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Artboard"
          transform="translate(-446.000000, -679.000000)"
          fill="#196F3D"
          fillRule="nonzero"
        >
          <g
            id="Feature-Box-Copy-2"
            transform="translate(230.000000, 662.000000)"
          >
            <g id="Group-3" transform="translate(200.000000, 0.000000)">
              <g id="leaf-38" transform="translate(16.000000, 17.000000)">
                <path
                  d="M23.2002712,33.3347797 C18.096,34.2288814 14.200678,38.5252881 14.0379661,38.7075254 C13.7605424,39.0174915 13.7613559,39.4861017 14.0395932,39.7944407 C14.2665763,40.0458305 19.2984407,45.559322 24.247322,45.559322 C24.6069153,45.559322 24.9681356,45.5300339 25.3252881,45.4673898 C31.9582373,44.3056271 35.9609492,38.2267119 36.1285424,37.968 C36.3669153,37.5986441 36.2692881,37.1064407 35.9080678,36.856678 C35.6371525,36.6687458 29.2018983,32.2820339 23.2002712,33.3347797 Z"
                  id="Shape"
                ></path>
                <path
                  d="M17.9007458,30 C17.7778983,29.732339 14.8287458,23.4296949 10.4851525,22.8667119 C5.05383051,22.1605424 0.485694915,25.9077966 0.292881356,26.0680678 C-0.045559322,26.3495593 -0.098440678,26.8482712 0.173288136,27.1948475 C0.337627119,27.4047458 4.26142373,32.352 9.17288136,32.9882034 C9.59755932,33.0435254 10.0214237,33.0679322 10.4395932,33.0679322 C14.1372203,33.0679322 17.4264407,31.1308475 17.5793898,31.0389153 C17.9357288,30.8257627 18.0748475,30.3774915 17.9007458,30 Z"
                  id="Shape"
                ></path>
                <path
                  d="M23.3393898,21.1525424 C23.5281356,21.1525424 23.7185085,21.0866441 23.8714576,20.9540339 C24.0903051,20.7652881 29.2157288,16.2695593 29.6395932,10.9456271 C30,6.41491525 27.2094915,2.16976271 27.0898983,1.99159322 C26.859661,1.64583051 26.4048814,1.52949153 26.036339,1.72149153 C25.7540339,1.86874576 19.1210847,5.38169492 18.7460339,10.0783729 C18.2782373,15.963661 22.5583729,20.6920678 22.7397966,20.8905763 C22.9008814,21.0638644 23.1197288,21.1525424 23.3393898,21.1525424 Z"
                  id="Shape"
                ></path>
                <path
                  d="M45.2908475,27.4348475 C43.6157288,21.6130169 36.891661,17.767322 36.6061017,17.6062373 C36.2212881,17.3898305 35.7372203,17.5191864 35.5094237,17.8942373 C35.3515932,18.1562034 31.659661,24.384 33.5105085,30.8216949 C34.9814237,35.9365424 43.1528136,38.3430508 43.4993898,38.4431186 C43.5742373,38.4642712 43.6498983,38.4748475 43.7239322,38.4748475 C44.0469153,38.4748475 44.3495593,38.2812203 44.4772881,37.9688136 C44.5700339,37.7434576 46.7162034,32.3894237 45.2908475,27.4348475 Z"
                  id="Shape"
                ></path>
                <path
                  d="M47.1864407,39.0508475 C25.9728814,39.0508475 10.5762712,22.9692203 10.5762712,0.813559322 C10.5762712,0.364474576 10.9399322,0 11.3898305,0 C11.8397288,0 12.2033898,0.364474576 12.2033898,0.813559322 C12.2033898,22.0271186 26.9157966,37.4237288 47.1864407,37.4237288 C47.636339,37.4237288 48,37.7882034 48,38.2372881 C48,38.6863729 47.636339,39.0508475 47.1864407,39.0508475 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
